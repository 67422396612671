import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import { safeParseURL } from '@dte/otw/utils/url/src/safeParseURL';
import { useMemo } from 'react';
import { joinUrlPaths } from '../../../app/(client)/(authenticated)/orders/(orderId)/reportedCalls/getPathSection';
import { useSubscriptionKey } from '../../../components/subscriptionKey/useSubscriptionKey';

export function useAuthenticatedUrl(path: string): string {
	const [subscriptionKey] = useSubscriptionKey();

	const url = useMemo(() => {
		if (!subscriptionKey) {
			return undefined;
		}
		const normalizedPath = normalizeValue(path);
		if (!normalizedPath) {
			return undefined;
		}

		const apiHost = process.env.NEXT_PUBLIC_API_BASE;
		let newUrl = joinUrlPaths([apiHost, normalizedPath]);
		newUrl = safeParseURL(newUrl);
		return newUrl;
	}, [subscriptionKey, path]);

	return url;
}
