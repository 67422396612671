import type { ErrorResponse } from '@dte/otw/azure-functions/src/common/interfaces/Error';
import { stringifyJSON } from '@dte/otw/utils/core/src/objects/stringifyJSON';
import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import { safeParseURL } from '@dte/otw/utils/url/src/safeParseURL';
import type { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { secondsToMilliseconds } from 'date-fns';
import { getTokenExpiration, getTokenUser } from '../../../contexts/TokenContext/isGoodToken';
import { logError, logInfo } from '../logging/writeLog';
import { fetchWithRetry } from '../network/fetch/fetchWithRetry';
import { BadSubscriptionKeyError, ForbiddenError, UnauthorizedError } from './BadSubscriptionKeyError';
import { getResponseBody } from './getResponseBody';

export function getRequestInit(method = 'get', body = undefined): RequestInit {
	// Stringify JSON body if needed
	let bodyString: string;
	if (body !== undefined) {
		if (typeof body === 'string') {
			bodyString = body;
		} else {
			bodyString = stringifyJSON(body);
		}
	}

	const requestInit: RequestInit = {
		headers: {
			Accept: 'application/json',
		},
		method: method,
		mode: 'cors',
		credentials: 'omit',
		body: bodyString,
		keepalive: false,

		signal: AbortSignal.timeout(secondsToMilliseconds(40)),
	};

	if (bodyString) {
		requestInit.headers['Content-Type'] = 'application/json';
	}

	// TODO: trace is mostly deprecated
	// Add trace header for non-prod environments
	// if (!environmentConfig.isProd) {
	// 	requestInit.headers['Ocp-Apim-Trace'] = true;
	// }

	return requestInit;
}

function getAuthenticatedRequestInit(
	subscriptionKey: string,
	token: string,
	method = 'get',
	body = undefined,
): RequestInit {
	const requestInit = getRequestInit(method, body);

	requestInit.headers = {
		...requestInit.headers,
		'Ocp-Apim-Subscription-Key': subscriptionKey,
		Authorization: `Bearer ${token}`,
	};

	return requestInit;
}

async function handleResponse<T>(appInsights: ReactPlugin, response: Response, token: string) {
	if (!response) {
		logError(appInsights, 'Error: empty response');
		return undefined;
	}

	const responseBody = await getResponseBody<T>(appInsights, response);
	if (response.ok) {
		return responseBody;
	}

	const errorBody = responseBody as ErrorResponse;
	handleErrorResponse(appInsights, response, errorBody, token);
}

function handleErrorResponse(appInsights: ReactPlugin, response: Response, body: ErrorResponse, token: string): void {
	// See if there's an issue with the subscription key
	checkBadSubscriptionKey(appInsights, response, body);

	// Check for 401 response
	checkNotAuthenticated(appInsights, response, body, token);

	// User doesn't have access
	checkForbidden(appInsights, response, body, token);

	// TODO: if this is an error we need to detect and handle it better
	const errorMessage = normalizeValue(body?.error?.message);
	if (errorMessage) {
		throw new Error(`Error retrieving data: ${errorMessage}`);
	}
	throw new Error('Error retrieving data');
}

function checkNotAuthenticated(appInsights: ReactPlugin, response: Response, body: ErrorResponse, token: string) {
	if (response.status !== 401) {
		return undefined;
	}

	checkTokenExpired(appInsights, response, body, token);

	throw new UnauthorizedError('Need to authenticate');
}

function checkForbidden(appInsights: ReactPlugin, response: Response, body: ErrorResponse, _token: string) {
	// Not a 401
	if (response.status !== 403) {
		return undefined;
	}

	const code = body?.error?.code;

	alert("Your account doesn't have the correct roles for access to this application");
	logError(appInsights, 'Unauthorized, error code: ', { code: code });

	throw new ForbiddenError('Not Authorized');
}

function checkTokenExpired(appInsights: ReactPlugin, _response: Response, body: ErrorResponse, token: string): void {
	const code = body?.error?.code;

	let message: string;

	// TODO: need to be looking at the refreshOn from the authentication result here

	// We expect to see token expired errors
	if (['TOKEN_EXPIRED'].includes(code)) {
		// TODO: try making a silent attempt to acquire a new token
		const user = getTokenUser(token);
		const expiryDate = getTokenExpiration(token);
		message = `Token error: [ ${code} ], user: [ ${user} ], token expiration: [ ${expiryDate?.toISOString()} ]`;
		logInfo(appInsights, message);
	}

	// We shouldn't be seeing these types of errors
	if (['TOKEN_NOT_FOUND', 'TOKEN_INVALID'].includes(code)) {
		const user = getTokenUser(token);
		const expiryDate = getTokenExpiration(token);
		message = `Token error: [ ${code} ], user: [ ${user} ], token expiration: [ ${expiryDate?.toISOString()} ]`;
		logError(appInsights, message);
	}

	throw new UnauthorizedError('Invalid token');
}

function checkBadSubscriptionKey(appInsights: ReactPlugin, _response: Response, body: ErrorResponse) {
	let badSubscriptionKey = false;

	const code = body?.error?.code;

	if (['KEY_INVALID', 'KEY_NOT_FOUND'].includes(code)) {
		badSubscriptionKey = true;
	}

	// TODO: this may also need to look at 403 errors coming from APIM if a key is used but not allowed for this app

	if (badSubscriptionKey) {
		// Should never get here as long as app config is correct
		const message = 'Bad subscription key';
		logError(appInsights, message);
		throw new BadSubscriptionKeyError(code, message);
	}
}

export async function authenticatedFetch<T>(
	appInsights: ReactPlugin,
	subscriptionKey: string,
	getToken: () => Promise<string>,
	url: string,
	method = 'get',
	body = undefined,
) {
	const validURL = safeParseURL(url);
	if (!validURL) {
		return undefined;
	}

	const token = await getToken();
	if (!token) {
		return undefined;
	}

	const requestInit = getAuthenticatedRequestInit(subscriptionKey, token, method, body);
	const requestInfo: RequestInfo = validURL;

	const fetchResponse = await fetchWithRetry(requestInfo, requestInit);
	const responseBody = await handleResponse<T>(appInsights, fetchResponse, token);
	return responseBody;
}
