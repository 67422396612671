import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import type { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { logError, logException } from '../logging/writeLog';

export async function getResponseBody<T>(appInsights: ReactPlugin, response: Response): Promise<T> {
	// Shortcut if we didn't have a response object
	if (!response) {
		return undefined;
	}

	// Clone the response first to avoid errors if we try to read it more than once
	const clonedResponse = response.clone();

	let body: T;
	let text: string;
	try {
		// Get as text first in case the server responded with something that wasn't JSON
		text = await clonedResponse.text();
		text = normalizeValue(text);

		// Response isn't JSON
		if (text === undefined) {
			return undefined;
		}

		// Got back an HTML response instead of a JSON response
		if (text.startsWith('<')) {
			logError(appInsights, 'Got back an HTML response instead of a JSON response', { text: text });
			return undefined;
		}

		// Attempt to parse
		const responseBody = JSON.parse(text);
		body = responseBody as T;
	} catch (error) {
		logError(appInsights, 'Unable to process response', { text: text });
		logException(appInsights, error);
	}

	return body;
}
