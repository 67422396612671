import { filterEmpty } from '@dte/otw/utils/core/src/lists/filterEmpty';
import { isEmpty } from '@dte/otw/utils/core/src/objects/isEmpty';

export function joinUrlPaths(paths: string[]) {
	let filteredPaths = filterEmpty(paths);
	filteredPaths = filteredPaths.map((path) => path.replace(/^\/|\/$/g, ''));

	if (isEmpty(filteredPaths)) {
		return undefined;
	}

	return filteredPaths.join('/');
}
