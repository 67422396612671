import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { isProd } from '../../common/util/environmentConfig';

export const InvalidSubscriptionKey = 'INVALID';

const envValue = process.env.NEXT_PUBLIC_API_SUBSCRIPTION_KEY;

export function useSubscriptionKey(): [string, (newValue: string) => void] {
	const router = useRouter();
	const [storageValue, setStorageValue] = useLocalStorage('subscriptionKey', '', { raw: true });
	const [validValue, setValidValue] = useState<boolean>(true);

	const value = envValue ?? storageValue;

	const setValue = useCallback(
		(newValue: string) => {
			const normalized = normalizeValue(newValue);
			if (normalized === InvalidSubscriptionKey) {
				setValidValue(false);
				return;
			}

			// Do nothing if we already have a value defined in the config
			if (envValue) {
				return;
			}
			setStorageValue(normalized);
		},
		[setStorageValue],
	);

	useEffect(() => {
		// Value looks OK
		if (validValue && value) {
			return;
		}

		// Can't redirect until router is initialized
		if (!router) {
			return;
		}

		// TODO: need better handling for prod if the key is missing / invalid
		if (isProd) {
			alert('Invalid subscription key');
			return;
		}
		router.push('/debug/test?invalidKey=true');
	}, [value, validValue, router]);

	return [value, setValue];
}
